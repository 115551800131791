exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-desktop-tsx": () => import("./../../../src/pages/desktop.tsx" /* webpackChunkName: "component---src-pages-desktop-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instructions-tsx": () => import("./../../../src/pages/instructions.tsx" /* webpackChunkName: "component---src-pages-instructions-tsx" */),
  "component---src-pages-key-setup-add-app-limit-tsx": () => import("./../../../src/pages/key-setup/add-app-limit.tsx" /* webpackChunkName: "component---src-pages-key-setup-add-app-limit-tsx" */),
  "component---src-pages-key-setup-add-hidden-app-limit-tsx": () => import("./../../../src/pages/key-setup/add-hidden-app-limit.tsx" /* webpackChunkName: "component---src-pages-key-setup-add-hidden-app-limit-tsx" */),
  "component---src-pages-key-setup-add-website-limit-tsx": () => import("./../../../src/pages/key-setup/add-website-limit.tsx" /* webpackChunkName: "component---src-pages-key-setup-add-website-limit-tsx" */),
  "component---src-pages-key-setup-enable-block-after-limit-tsx": () => import("./../../../src/pages/key-setup/enable-block-after-limit.tsx" /* webpackChunkName: "component---src-pages-key-setup-enable-block-after-limit-tsx" */),
  "component---src-pages-key-setup-enable-downtime-custom-times-tsx": () => import("./../../../src/pages/key-setup/enable-downtime-custom-times.tsx" /* webpackChunkName: "component---src-pages-key-setup-enable-downtime-custom-times-tsx" */),
  "component---src-pages-key-setup-enable-downtime-tsx": () => import("./../../../src/pages/key-setup/enable-downtime.tsx" /* webpackChunkName: "component---src-pages-key-setup-enable-downtime-tsx" */),
  "component---src-pages-key-setup-enable-passcode-tsx": () => import("./../../../src/pages/key-setup/enable-passcode.tsx" /* webpackChunkName: "component---src-pages-key-setup-enable-passcode-tsx" */),
  "component---src-pages-key-setup-tsx": () => import("./../../../src/pages/key-setup.tsx" /* webpackChunkName: "component---src-pages-key-setup-tsx" */),
  "component---src-pages-key-setup-unlock-with-key-tsx": () => import("./../../../src/pages/key-setup/unlock-with-key.tsx" /* webpackChunkName: "component---src-pages-key-setup-unlock-with-key-tsx" */),
  "component---src-pages-key-tsx": () => import("./../../../src/pages/key.tsx" /* webpackChunkName: "component---src-pages-key-tsx" */),
  "component---src-pages-learn-more-tsx": () => import("./../../../src/pages/learn-more.tsx" /* webpackChunkName: "component---src-pages-learn-more-tsx" */),
  "component---src-pages-screentime-tsx": () => import("./../../../src/pages/screentime.tsx" /* webpackChunkName: "component---src-pages-screentime-tsx" */),
  "component---src-pages-setup-tsx": () => import("./../../../src/pages/setup.tsx" /* webpackChunkName: "component---src-pages-setup-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */)
}

